@font-face {
  font-family: "Pierson";
  src: url("./assets/fonts/Pierson-400.otf");
}

@font-face {
  font-family: "Neue Montreal Light";
  src: url("./assets/fonts/NeueMontreal-Light.otf");
}

@font-face {
  font-family: "Neue Montreal Regular";
  src: url("./assets/fonts/NeueMontreal-Regular.otf");
}

@font-face {
  font-family: "Forela";
  src: url("./assets/fonts/Farela-Regular.otf");
}

@font-face {
  font-family: "Outfit";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit&display=swap");
}

:root {
  --primary-color: #284020;
  --secondary-color: #f7ebda;
  --tertiary-color: #121b11;
  --header-text-color: #121b11;
  --accent-color: #eb3d00;
  --text-color: #121b11;
  --hover-color: #ffbda9;
}

body {
  background-color: var(--secondary-color);
  font-family: "Outfit", sans-serif;
  color: var(--text-color);
}

h1 {
  font-family: "Forela", sans-serif;
  color: var(--header-text-color);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 8rem;
}

h2 {
  font-family: "Forela", sans-serif;
  font-size: 1.8rem;
  color: var(--accent-color);
}

h3 {
  font-family: "Forela", sans-serif;
  font-size: 1.4rem;
}

/** NAVBAR STYLING **/
#navbar button {
  border: none;
}

#offcanvasNavbar-expand-false {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  font-family: "Outfit", sans-serif;
}

#offcanvasNavbar-expand-false a:hover {
  font-family: "Outfit", sans-serif;
}

.bg-custom {
  background-color: var(--primary-color);
}

/** HERO IMAGE Styling **/

.header {
  position: relative;
}

.background-img {
  width: 100%;
}

.App-header {
  margin-top: -56px;
}

/** ABOUT ME SECTION **/

#about-me {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 40px 80px;
  text-align: center;
}

#about-me {
  font-family: "Outfit" sans-serif;
}

#about-me h2 {
  color: var(--hover-color);
}

#about-me p {
  letter-spacing: 0.7px;
}

#about-me a:hover {
  color: #4559ba;
}

.about-me-img {
  width: 20%;
  height: auto;
}

/** EXPERIENCE STYLING **/

.experience-img {
  width: 50%;
}

.description-box {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.description-box.odd {
  width: 50%;
}

.description-box.even {
  color: var(--text-color);
  font-size: 0.8rem;
  width: 50%;
}

.description h2,
.description h3 {
  text-align: center;
}

.experience ul {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 2rem;
  text-align: left;
  letter-spacing: 1px;
  text-align: left;
}

/** PROJECTS **/
#projects {
  padding: 30px 0;
}

#projects h2 {
  display: flex;
  justify-content: center;
  font-size: 3rem;
}

#projects h3,
#projects p {
  text-decoration: none;
  color: var(--accent-color);
  font-size: 2rem;
}

#projects h3 {
  font-family: "Forela", serif;
}

#projects p {
  font-family: "Outfit", serif;
}

#projects a {
  color: var(--hover-color);
  font-size: 1rem;
  text-decoration: none;
}

.projects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.projects > div {
  transition: 600ms;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.project-name,
.project-description {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

img {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: auto;
  opacity: 0.3;
}

.projects > div:hover {
  background: var(--primary-color);
  color: var(--secondary-color);
}

.projects > div:hover .project-description,
.projects > div:hover p {
  display: block;
  height: 100%;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects > div:hover .project-name {
  display: none;
}

.project-description {
  display: none;
}

/** FOOTER SECTION **/

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.social-icons > div {
  padding: 20px;
}

svg {
  transition: 500ms;
  fill: var(--primary-color);
}

svg:hover {
  fill: var(--accent-color);
}

/** RESPONSIVENESS **/

@media (max-width: 600px) {
  .navbar-collapse.collapse.show > a {
    margin-top: 15px;
  }

  .App-header {
    min-height: 30vh;
    background-attachment: fixed;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .experience {
    flex-direction: column;
    width: 100%;
  }

  div.experience:nth-child(even) {
    background: var(--primary-color);
    color: var(--text-color);
  }

  div.experience:nth-child(even) {
    color: var(--secondary-color);
  }

  div.experience:nth-child(even) h2 {
    color: var(--hover-color);
  }

  .description-box {
    padding: 40px 80px;
  }

  h1 {
    font-size: 3rem;
  }

  .experience h2 {
    font-size: 1.2rem;
  }

  .experience h3 {
    font-size: 1rem;
  }

  .experience-img {
    display: none;
  }

  .description-box.odd {
    width: 100%;
  }

  .description-box.even {
    width: 100%;
  }

  .projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .projects > div {
    width: 100%;
    height: 250px;
    overflow: hidden;
  }
}
